import { React } from "react";

export default function Results(props) {
  
  return (
    <div>
      <p id="result">Enter text above then click Process</p>
      <audio id="audioPlayback" controls>
        <source id="audioSource" type="audio/mp3" src=""></source>
      </audio>
      <p id="resultProc">&nbsp;</p>
      <audio id="audioPlaybackProc" controls>
        <source id="audioSourceProc" type="audio/mp3" src=""></source>
      </audio>
    </div>
  );
}