let worker = null
let queue = []
let isProcessing = false
let item = null
let stderr = ""

function processQueue() {
  getWorker().onmessage = parseLog
  if (!isProcessing) {
    isProcessing = true
    item = queue.shift()

    const inFilename = basename(item.fileObject.file.name)+"."+item.inputExt
    const outFilename = basename(item.fileObject.file.name)+"_out."+item.outputExt
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      const fileData = new Uint8Array(event.target.result);
      const inputArgs = ["-i", inFilename]
      const outputArgs = [outFilename]
      const args = [
        ...inputArgs,
        ...item.args,
        ...outputArgs
      ]
      console.log('Running ffmpeg '+args.join(' '))

      getWorker().postMessage({
        type: "run",
        arguments: args,
        MEMFS: [{name: inFilename, data: fileData}],
      });
    });
    reader.readAsArrayBuffer(item.blob)
  }
}

export function process(
  inputExt,
  outputExt,
  args,
  fileObject,
  blob,
  progressCallback,
  successCallback,
  failureCallback)
{
  queue.push({
    fileObject,
    blob,
    inputExt,
    outputExt,
    args,
    progress: (progress) => {
      progressCallback(fileObject, progress)
    },
    success: (result) => {
      successCallback(fileObject, result)
    },
    failure: (error) => {
      failureCallback(fileObject, error)
    }
  })
  processQueue()
}

function parseTime(hms) {
    let [hours, mins, secs] = hms.split(':')
    let total_secs = Number(hours)*3600 + Number(mins)*60 + Number(secs)
    return total_secs
}

function parseLog (event) { 
  const msg = event.data;
  switch (msg.type) {
  case "ready":
    break;
  case "stdout":
    if (msg.data) console.log(msg.data)
    break;
  case "stderr":
    if (msg.data) {
      console.log(msg.data)
      stderr += msg.data
      let jsonObject = stderr.match(/\{[\s\S]*\}/)
      if (jsonObject) {
        item.success({loudness: JSON.parse(jsonObject)})
        stderr = ""
        return
      }
      let exceptions = msg.data.match(/^exception/m)
      if (exceptions) return item.failure('Exception thrown')
      let maxVol = msg.data.match(/max_volume: ([-\d.]+) dB/)
      if (maxVol && maxVol.length > 1) return item.success({maxVol: maxVol[1]})
      let time = msg.data.match(/time=(\d\d:\d\d:\d\d.\d\d)/)
      if (time && time.length > 1) return item.progress(parseTime(time[1]))
    }
    break;
  case "done":
    if ('data' in msg && 'MEMFS' in msg.data && msg.data.MEMFS.length > 0) {
      item.success(msg.data)
    } else {
      if (msg.data) console.log(msg.data)
    }
    isProcessing = false
    if (queue.length > 0) processQueue()
    break;
  }
}

function getWorker() {
  if (worker == null) {
    worker = new Worker("static/js/ffmpeg-worker.js");
    
  }
  return worker
}

function basename(filename) {
  return filename.substring(0, filename.lastIndexOf("."))
}

export function init() {
  getWorker().postMessage({type: "run", arguments: ["-muxers"]})
}
