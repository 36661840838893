import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'

export default function About(props) {
  return (
    <Box sx={{display: props.visible ? '?' : 'none', p: '20px'}}>
      <Container maxWidth='md'>
      {props.loggedIn ?
      <Typography variant="body1" gutterBottom>
        This is a tool for analysing and processing audio files.
        You can import almost any audio file, or video file containing audio,
        by pressing Import or dragging it onto the screen.
        Once imported, you can view key information about the audio, process
        it using plugins, and export it in various formats and configurations.
      </Typography>
      :
      <Typography variant="body1" gutterBottom>
        This is a tool for analysing and processing audio files.
        You can import almost any audio file, or video file containing audio,
        by pressing Import or dragging it onto the screen.
        Once imported, you can view key information about the audio and
        export it in various formats and configurations.
      </Typography>
      }
      <Typography variant="h6" gutterBottom>Loudness</Typography>
      <Typography variant="body1" gutterBottom>
        You can use this tool to measure and normalise the loudness of the audio.
        This is measured in Loudness Units referenced to full scale (LUFS).
        A loudness of -23 LUFS (EBU R128) is the recommended level for broadcast content
        throughout Europe and the standard for all BBC production content.
        A loudness of -18 LUFS (AES TD1008) is sometimes used for delivery to non-BBC
        and non-broadcast companies. To measure the loudness of a file, press the 'calculate'
        button in the Loudness column.  This will also measure the true peak value.
      </Typography>
      <Typography variant="h6" gutterBottom>Expert mode</Typography>
      <Typography variant="body1" gutterBottom>
        By default, the export settings are restricted to a set of values that
        cover the vast majority of use cases. To unlock the full range of options
        click the 'expert mode' toggle in the bottom left corner of the export window.
      </Typography>
      <Typography variant="h6" gutterBottom>Data handling</Typography>
      {props.loggedIn ?
      <Typography variant="body1" gutterBottom>
        The analysis and transcoding happens locally on your device, but some plugins upload
        your data to a secure server. This is clearly indicated in each plugin's details page.
        We collect some anonymous statistics about usage of the tool, such as number of
        imports and exports, format and duration.
      </Typography>
      :
      <Typography variant="body1" gutterBottom>
        All of the analysis and processing happens locally on your device, so
        the audio is not send over the Internet. We collect some anonymous statistics
        about usage of the tool, such as number of imports and exports, format and duration.
      </Typography>
      }
      <Typography variant="h6" gutterBottom>Development</Typography>
      <Typography variant="body1" gutterBottom>
        This tool was created by BBC Research &amp; Development. It was built 
        using <Link href="https://github.com/Kagami/ffmpeg.js/" target="_blank">ffmpeg.js</Link>
        , <Link href="https://github.com/buzz/mediainfo.js" target="_blank">mediainfo.js</Link>
        , and <Link href="https://mui.com/material-ui/getting-started/overview/" target="_blank">Material UI</Link>.
        We would love to hear your feedback using the link in the sidebar. 
      </Typography>
      </Container>
    </Box>
  )
}
