import React, { useState, useEffect } from 'react'
import DragAndDrop from './DragAndDrop'
import OptionsRail from './OptionsRail'
import Grid from '@mui/material/Unstable_Grid2'
import {process} from './ffmpeg'
import {analyse} from './mediainfo'
import ExportModal from './ExportModal'
import {logAction} from './Analytics'
import './Tool.css'

let plugins = []
import('./server-plugins/PluginList.js').then(module => {
  module.listPlugins().then((pluginList) => plugins.push(...pluginList))
})

const sampleFormatMap = {
  's16le': 's16',
  's24le': 's32' // quirk of ffmpeg flac encoder - ask for 32-bit, get 24-bit
}

const loudnessFilter = {
  ebur128: "volumedetect,loudnorm=I=-23:TP=-1:LRA=15:linear=true:print_format=json",
  aestd1008: "volumedetect,loudnorm=I=-18:TP=-1:LRA=11:linear=true:print_format=json",
  'peak-1': "volumedetect",
  'peak-10': "volumedetect"
}

const loudnessParams = {
  ebur128: "loudnorm=I=-23:TP=-1:LRA=15",
  aestd1008: "loudnorm=I=-18:TP=-1:LRA=11"
}

const buildFfmpegArgs = (config, file) => {
  let args = []

  // sample rate
  if (config.sampleRate === '') config.sampleRate = file.SamplingRate
  args = [...args, "-ar", config.sampleRate]

  // sample format
  if (config.sampleFormat !== '' && config.format === 'flac') {
    args = [...args, "-sample_fmt", sampleFormatMap[config.sampleFormat]]
  } else if (config.sampleFormat !== '' && config.format === 'wav') {
    args = [...args, "-acodec", `pcm_${config.sampleFormat}`]
  }

  // normalisation
  if (config.normalise === 'ebur128' || config.normalise === 'aestd1008') {
    let loudnorm = loudnessParams[config.normalise]+
                   `:measured_I=${file.loudness.input_i}`+
                   `:measured_LRA=${file.loudness.input_lra}`+
                   `:measured_TP=${file.loudness.input_tp}`+
                   `:measured_thresh=${file.loudness.input_thresh}`+
                   `:offset=${file.loudness.target_offset}`+
                   ':linear=true:print_format=summary'
    args = [...args, "-af", loudnorm]
  } else if (config.normalise === 'peak-1') {
    const gain = -1 - parseFloat(file.maxVol)
    args = [...args, "-af", `volume=${gain}dB`]
  } else if (config.normalise === 'peak-10') {
    const gain = -10 - parseFloat(file.maxVol)
    args = [...args, "-af", `volume=${gain}dB`]
  }
  return args
}

const calculateLoudness = (type, file, setFiles) => {
  return new Promise((resolve, reject) => {

    // if no normalisation, return the original file object
    if (type==='') return resolve(file)

    // return original file object if already extracted
    if ('loudness' in file &&
        'type' in file.loudness &&
        file.loudness.type===type)
      return resolve(file)
    
    // return original file object if already extracted
    if ((type==='peak-1' || type==='peak-10') &&
        'maxVol' in file)
      return resolve(file)
    
    // set stage to 'queued'
    updateFile(file.id, {stage: "Queued"}, setFiles)

    // call ffmpeg to extract loudness/volume
    process(
      file.file.name.substring(file.file.name.lastIndexOf('.')+1),
      'wav',
      ["-map", "0:a", "-af", loudnessFilter[type], "-f", "null"],
      file,
      file.file,

      // update progress bar
      (fileObject, progress) => updateFile(
        fileObject.id,
        {stage: "Analysing",
        progress: progress/Number(fileObject.Duration)},
        setFiles),

      // if data returned
      (fileObject, result) => {

        // update file with data
        let fileUpdates = {stage: "", progress: 1}
        if ('loudness' in result)
          fileUpdates = {...fileUpdates, loudness: {...result.loudness, type}}
        else
          fileUpdates = {...fileUpdates, ...result}
        updateFile(
          fileObject.id,
          fileUpdates,
          setFiles)

        // if expected result has been returned, resolve promise
        if (('loudness' in result && (type==='ebur128' || type==='aestd1008')) ||
            ('maxVol' in result && (type==='peak-1' || type==='peak-10')))
          resolve({...file, ...fileUpdates})
      },

      // on error, reject promise
      (fileObject, error) => {
        updateFile(
          fileObject.id,
          {stage: "Failed", process: 1},
          setFiles)
        reject(error)
      }
    )
  })
}

const updateFile = (id, data, setFiles) => {
  setFiles(oldFiles => oldFiles.map((item) => 
    item.id === id ? {...item, ...data} : item
  ))
}

const removeSelected = (selected, setFiles) => {
  setFiles((oldFiles) => oldFiles.filter(file =>
    selected.find((item) => item === file.id) === undefined)
  )
}

const handleExport = (config, files, setFiles, selected) => {
  for (let selectedID of selected) {
    let selectedFile = files.find(item => item.id === selectedID)
    if (selectedFile === undefined) return console.log("File not found")
    calculateLoudness(config.normalise, selectedFile, setFiles)
    .then((file) => {
      console.log("Original file")
      console.log(file)
      return handlePlugins(config, file, setFiles)
    })
    .then((result) => {
      const args = buildFfmpegArgs(config, result.fileObject)
      updateFile(result.fileObject.id, {stage: "Queued"}, setFiles)
      logAction('export', {
        format: config.format,
        normalise: config.normalise,
        duration: result.fileObject.Duration
      })
      process(
        result.format,
        config.format,
        args,
        result.fileObject,
        result.blob,
        (fileObject, progress) => updateFile(
          fileObject.id,
          {stage: "Processing", progress: progress/Number(fileObject.Duration)},
          setFiles),
        (fileObject, msg) => {
          handleOutputFile(fileObject, msg)
          updateFile(fileObject.id, {stage: "", progress: 1}, setFiles)
        }
      )
    })
    .catch((e) => {
      console.error(e)
      updateFile(selectedID, {stage: "Failed", progress: 1}, setFiles)
    })
  }
}

// TODO Finish this function
const handlePlugins = (config, inputFile, setFiles) => {
  console.log("Plugin handler")
  console.log(config.selectedPlugins)
  return new Promise((res, rej) => {
    if (config.selectedPlugins.length === 0) {
      console.log("No plugins selected. Continuing...")
      const filename = inputFile.file.name
      const format = filename.substring(filename.lastIndexOf('.')+1)
      return res({
        fileObject: inputFile,
        format,
        blob: inputFile.file
      })
    }
    import('./server-plugins/PluginList.js').then(module => {
      config.selectedPlugins.reduce((p, plugin) => {
        console.log(`Running  plugin ${plugin.name}`)
        return p.then((file) => {
          return module.process(
            plugin,
            file,
            process.env.REACT_APP_S3_UPLOAD_ENDPOINT,
            (update) => {
              updateFile(file.id, update, setFiles)
            }
          )
        })
        .catch((error) => rej(error))
      }, Promise.resolve(inputFile))
      .then((result) => {
        console.log("Finished running plugins")
        console.log(result)
        res(result)
      })
    })
  })
}

const handleDownload = (files, setFiles, selected) => {
  for (const selectedID of selected) {
    let selectedFile = files.find(item => item.id === selectedID)
    if (selectedFile === undefined) return console.log("File not found")
    if (!('s3key' in selectedFile)) return console.log("S3 key not found")
    const searchParams = new URLSearchParams({ name: selectedFile.s3key })
    return fetch(process.env.REACT_APP_S3_DOWNLOAD_ENDPOINT+'?'+searchParams)
    .then((res) => res.json())
    .then((data) => {
      if (!('url' in data)) {
        console.log("Could not get pre-signed URL")
        return null
      } else {
        var elem = document.createElement("a");
        elem.href = data.url;
        elem.setAttribute('download', true);
        elem.download = selectedFile.name;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
        return null
      }
    })
  }
}

const handleOutputFile = (fileObject, msg) => {
  for (let i=0; i<msg.MEMFS.length; i++) {
    var file = msg.MEMFS[i]
    var data = new Blob([file.data.buffer])
    var elem = document.createElement("a");
    elem.href = URL.createObjectURL(data);
    elem.download = file.name;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
}

const handleDrop = (files, setFiles, filesProcessing, setFilesProcessing) => {
  const now = new Date();
  let newFiles = []
  setFilesProcessing(filesProcessing+files.length)
  for (var i = 0; i < files.length; i++) {
    if (!files[i].name) return
    const id = files[i].name + now.toISOString()
    newFiles.push({
      id,
      file: files[i]
    })
    analyse({file: files[i], id}, (file, data) => {
      if (!data) data = {stage: 'No audio'}
      console.log(data)
      updateFile(file.id, data, setFiles)
      setFilesProcessing(filesProcessing-1)
      if ('Duration' in data) {
        // if (parseFloat(data.Duration) < 120)
        //   calculateLoudness({...file, ...data}, setFiles)
        logAction('import', {
          duration: data.Duration,
          format: data.Format
        })
      }
    })
  }
  setFiles((oldFiles) => [...oldFiles, ...newFiles])
}

export default function Tool(props) {  

  const [files, setFiles] = useState([])
  const [filesProcessing, setFilesProcessing] = useState(0)
  const [selected, setSelected] = useState([])
  const [showExportModal, setShowExportModal] = useState(false)
  const [exportEnabled, setExportEnabled] = useState(false)

  useEffect(() => {
    let enabled = true
    if (selected.length === 0) enabled = false
    for (const selectedID of selected) {
      let selectedFile = files.find(item => item.id === selectedID)
      if (selectedFile !== undefined && 'stage' in selectedFile && selectedFile.stage === "No audio")
        enabled = false
    }
    setExportEnabled(enabled)
  }, [files, selected])

  return (
    <Grid container alignItems="flex-end" spacing={0} sx={{
      flexDirection: 'column',
      flex: 1,
      display: props.visible ? '?' : 'none'
      }}>
      <Grid container xs={12} sx={{flex: 1}}>
        <DragAndDrop
          handleDrop={(droppedFiles) => handleDrop(droppedFiles, setFiles, filesProcessing, setFilesProcessing)}
          getRowId={obj => obj.id}
          rows={files}
          isLoading={filesProcessing>0}
          onSelectionModelChange={(selectionModel) =>
            setSelected(selectionModel)
          }
          selectionModel={selected}
          onRemoveSelection={() => removeSelected(selected, setFiles)}
          calculateLoudness={(id) => {
            let selectedFile = files.find(item => item.id === id)
            if (selectedFile === undefined) return console.log("File not found")
            calculateLoudness('ebur128', selectedFile, setFiles)
            .catch((e) => console.error(e))
          }}
        />
      </Grid>
      <OptionsRail
        importHandler={(droppedFiles) => handleDrop(droppedFiles, setFiles, filesProcessing, setFilesProcessing)}
        exportHandler={() => setShowExportModal(true)}
        downloadHandler={() => handleDownload(files, setFiles, selected)}
        enabled={exportEnabled}
      />
      <ExportModal
        open={showExportModal}
        onClose={() => setShowExportModal(false)}
        handleExport={(config) => handleExport(config, files, setFiles, selected)}
        plugins={plugins}
        files={files}
        selectedFiles={selected}
      />
    </Grid>
  )
}