import React from 'react'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Unstable_Grid2'

export default function OptionsRail(props) {
  return (
    <Grid container xs={12} justifyContent="space-between"
      alignItems="center" sx={{p:1, borderTop: '1px solid rgba(0, 0, 0, 0.12)'}}>
      <Grid xs={12} md="auto">
        <Stack spacing={2} direction="row" sx={{p: '10px'}}>
          <Button
            variant="contained"
            component="label">
            Import
            <input hidden multiple type="file"
              onChange={(e) => props.importHandler(e.target.files)}/>
          </Button>
          <Button
            variant="contained"
            onClick={props.exportHandler}
            disabled={!props.enabled}>
            Export
          </Button>
        </Stack>
      </Grid>
      <Grid xs={12} md="auto">
        <Stack spacing={2} direction="row" sx={{justifyContent: 'center'}}>
          <Button
            href="https://www.bbc.co.uk/usingthebbc/terms/"
            target="_blank"
            sx={{p:0, fontSize: 'calc(0.6em + 0.5vmin)'}}
            color="grey">
              Terms of Use
          </Button>
          <Button
            href="https://www.bbc.co.uk/usingthebbc/privacy/"
            target="_blank"
            sx={{p:0, fontSize: 'calc(0.6em + 0.5vmin)'}}
            color="grey">
              Privacy notice
          </Button>
          <Button
            href="https://www.bbc.co.uk/usingthebbc/cookies"
            target="_blank"
            sx={{p: '0', fontSize: 'calc(0.6em + 0.5vmin)', paddingRight: 1}}
            color="grey">
              Cookie policy
          </Button>
        </Stack>
      </Grid>
    </Grid>
  )
}