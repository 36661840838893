import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import MuiAppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Divider from '@mui/material/Divider';
import InfoIcon from '@mui/icons-material/Info';
import BadgeIcon from '@mui/icons-material/Badge';
import HomeIcon from '@mui/icons-material/Home';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ScienceIcon from "@mui/icons-material/Science";
import About from './About'
import Extra from "./Extra";
import Tool from './Tool'
import {logView} from './Analytics'
import './App.css';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function App() {

  const [open, setOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState('home')
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    logView(selectedItem)
  }, [selectedItem])

  useEffect(() => {
    import('./server-plugins/PluginList.js').then(module => {
      setLoggedIn(true)
    })
  }, [])

  const handleDrawerOpen = () => {
    setOpen(true)
  };

  const handleDrawerClose = () => {
    setOpen(false)
  };

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} color="white">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <img src="bbc-black.svg" alt="BBC Logo" className="logo" />
          <Stack
            direction="row"
            spacing={3}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Typography variant="h6" noWrap component="div">
              Audio Tool
            </Typography>
            {loggedIn && (
              <Tooltip title="Additional features are automatically unlocked when accessed from a BBC computer">
                <Chip
                  icon={<BadgeIcon />}
                  label="Staff edition"
                  variant="outlined"
                  color="primary"
                />
              </Tooltip>
            )}
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem key="home" disablePadding>
            <ListItemButton
              onClick={() => {
                setSelectedItem("home");
                setOpen(false);
              }}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItemButton>
          </ListItem>
          {loggedIn && (
          <ListItem key="extra" disablePadding>
            <ListItemButton
              onClick={() => {
                setSelectedItem("extra");
                setOpen(false);
              }}
            >
              <ListItemIcon>
                <ScienceIcon />
              </ListItemIcon>
              <ListItemText primary="Extra" />
            </ListItemButton>
          </ListItem>
          )}
          <ListItem key="about" disablePadding>
            <ListItemButton
              onClick={() => {
                setSelectedItem("about");
                setOpen(false);
              }}
            >
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText primary="About" />
            </ListItemButton>
          </ListItem>
          <ListItem key="feedback" disablePadding>
            <ListItemButton
              onClick={() => {
                if ("_urq" in window) {
                  window._urq.push(["Feedback_Open"]);
                  logView("feedback");
                } else {
                  alert(
                    "Could not open feedback system. Please disable any ad blockers."
                  );
                }
                setOpen(false);
              }}
            >
              <ListItemIcon>
                <FeedbackIcon />
              </ListItemIcon>
              <ListItemText primary="Feedback" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Main
        open={open}
        sx={{ display: "flex", flexDirection: "column", flex: 1, p: 0 }}
      >
        <DrawerHeader />
        <Tool visible={selectedItem === "home"} />
        <Extra visible={selectedItem === "extra"} loggedIn={loggedIn} />
        <About visible={selectedItem === "about"} loggedIn={loggedIn} />
      </Main>
    </Box>
  );
}
