import { React, useState, useEffect } from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton } from '@mui/x-data-grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'
import WarningIcon from '@mui/icons-material/Warning'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import './DragAndDrop.css'

const Toolbar = (selection, removeCallback) => {
  return (
    <GridToolbarContainer>
      <IconButton
        aria-label="delete"
        color="primary"
        disabled={!selection.length}
        onClick={removeCallback}
        sx={{p: '10px'}}>
        <DeleteIcon />
      </IconButton>
      <GridToolbarColumnsButton sx={{p: '10px'}} />
    </GridToolbarContainer>
  )
}

const renderFormat = (params) => {
  if (!('Format' in params.row)) return ''
  if (!(params.row.Format === 'MPEG Audio')) return params.row.Format
  if (!('Format_Profile' in params.row)) return params.row.Format
  else if (params.row.Format_Profile === 'Layer 3') return 'MP3'
  else if (params.row.Format_Profile === 'Layer 2') return 'MP2'
  else return params.row.Format
}

const renderProgress = (params) => {
  if (params.value>0.999) return ''
  else return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress variant="determinate" value={params.value*100 || 0} />
    </Box>
  )
}

const renderLoudness = (params, callback) => {
  if (params.value !== '') return params.value
  if (!params.row.Format) return ''
  return (
    <Button
      variant="text"
      disabled={('stage' in params.row && params.row.stage!=='')}
      onClick={() => callback(params.row.id)}>
      Calculate
    </Button>
  )
}

const renderTP = (params) => {
  if (!('loudness' in params.row)) return ''
  return (
    <div>{params.row.loudness.input_tp}
    {(parseFloat(params.row.loudness.input_tp) >= 0) &&
      <WarningIcon sx={{
        verticalAlign: 'bottom',
        color: 'red'}}
      />}
    </div>
  )
}

const renderDuration = (params) => {
  if (!('Duration' in params.row)) return ''
  return new Date(1000 * parseFloat(params.row.Duration)).toISOString().substr(11, 8)
}

const preventDefault = (e) => {
  e.preventDefault()
  e.stopPropagation()
}

const handleDrop = (e, dropCallback) => {
  preventDefault(e)
  if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
    dropCallback(e.dataTransfer.files)
  }
}

export default function DragAndDrop(props) {

  const [width, setWidth] = useState(window.innerWidth);
  const columns = [
    {
      field: 'file',
      headerName: 'File',
      minWidth: 200,
      flex: 1,
      valueGetter: (params) => params.row.file.name
    },
    {
      field: 'Format',
      headerName: 'Format',
      renderCell: renderFormat
    },
    {
      field: 'Duration',
      headerName: 'Duration',
      renderCell: renderDuration
    },
    {
      field: 'Channels',
      headerName: 'Channels'
    },
    {
      field: 'SamplingRate',
      headerName: 'Sample rate (Hz)',
      minWidth: 130
    },
    {
      field: 'BitRate',
      headerName: 'Bitrate (kbps)',
      minWidth: 110,
      renderCell: (params) => ('BitRate' in params.row) ? `${parseInt(params.row.BitRate)/1000}` : ''
    },
    {
      field: 'BitDepth',
      headerName: 'Bit depth (bits)',
      minWidth: 120
    },
    {
      field: 'loudness',
      headerName: 'Loudness (LUFS)',
      minWidth: 130,
      valueGetter: (params) => ('loudness' in params.row) ? params.row.loudness.input_i : '',
      renderCell: (params) => renderLoudness(params, props.calculateLoudness)
    },
    {
      field: 'truepeak',
      headerName: 'True Peak (dB)',
      minWidth: 120,
      valueGetter: (params) => ('loudness' in params.row) ? params.row.loudness.input_tp : '',
      renderCell: renderTP
    },
    {
      field: 'maxVol',
      headerName: 'Max Volume (dB)',
      minWidth: 130
    },
    {
      field: 'stage',
      headerName: 'Stage'
    },
    {
      field: 'progress',
      headerName: 'Progress',
      renderCell: renderProgress
    },
  ]

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    file: true,
    Format: true,
    Duration: true,
    Channels: false,
    SamplingRate: false,
    BitRate: false,
    BitDepth: false,
    loudness: true,
    truepeak: true,
    maxVol: false,
    stage: true,
    progress: true
  })

  useEffect(() => {
    function handleResize() {
        setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setColumnVisibilityModel({
      ...columnVisibilityModel,
      loudness: (width > 950),
      truepeak: (width > 950),
      Format: (width > 650),
      Duration: (width > 650),
      progress: (width > 450),
      stage: (width > 350)
    })
  }, [width])

  return (
    <div className="tableContainer"
         onDrag={preventDefault}
         onDragEnter={preventDefault}
         onDragLeave={preventDefault}
         onDragOver={preventDefault}
         onDrop={(e) => handleDrop(e, props.handleDrop)}>
      <DataGrid
        rows={props.rows}
        columns={columns}
        loading={props.isLoading}
        rowsPerPageOptions={[1000]}
        onSelectionModelChange={props.onSelectionModelChange}
        selectionModel={props.selectionModel}
        checkboxSelection
        disableSelectionOnClick
        disableColumnFilter
        disableDensitySelector
        hideFooter
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)}
        components={{
          NoRowsOverlay: () => (
            <div className="emptyMessage">
              Drag-and-drop your files here
            </div>
          ),
          Toolbar: () => Toolbar(props.selectionModel, props.onRemoveSelection),
        }}
        sx={{border: 0}}
      />
    </div>
  )
}