window.requirejs.config({ paths: {'echo' : 'https://mybbc-analytics.files.bbci.co.uk/echo-client-js/echo-12.19.0.min'} })
let echoInstance = null
let echoEnums = null

const getEcho = () => {
    return new Promise((resolve, reject) => {
        if (echoInstance && echoEnums)
            return resolve({instance: echoInstance, Enums: echoEnums})
        window.requirejs(['echo'], function(Echo)
        {
            const EchoClient = Echo.EchoClient
            echoEnums = Echo.Enums
            const ConfigKeys = Echo.ConfigKeys
            var conf = {};
            conf[ConfigKeys.DESTINATION] = echoEnums.Destinations.R_AND_D_PS
            echoInstance = new EchoClient(
                "audio",
                echoEnums.ApplicationType.WEB,
                conf
            )
            echoInstance.setAppVersion('1.1.1')
            resolve({instance: echoInstance, Enums: echoEnums})
        })
    })

}

export const logView = (pageName) => {
    getEcho().then((echo) => echo.instance.viewEvent(`audio.tools.${pageName}`))
}

export const logAction = (actionName, eventProps) => {
    getEcho().then((echo) => {
        if (!eventProps) eventProps = {}
        let vars = {}
        vars['item_type'] = eventProps.format || ''
        vars['item_dimensions'] = eventProps.duration || '0'
        vars['item_image'] = eventProps.normalise || ''
        echo.instance.viewEvent(`audio.tools.action.${actionName}`, vars)
    })
}