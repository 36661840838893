import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {generateSpeech} from './processPolly';
import Results from "./Results";

export default function Extra(props) {
  const [format, setFormat] = React.useState("mp3");
  const [voice, setVoice] = React.useState("Brian");
  const [textinput, setTextInput] = React.useState("Text to speak");

  const speak = () => {
    generateSpeech(format, voice, textinput);
  };
  return (
    <Box sx={{ display: props.visible ? "?" : "none", p: "20px" }}>
          {!props.loggedIn ? 
       <Container maxWidth="md">
         <Typography variant="body1" gutterBottom>
            RESTRICTED
          </Typography>
        </Container>
           :
      <Container maxWidth="md">
   

       
          <Typography variant="body1" gutterBottom>
            These are tools which have been requested from around the
            BBC
          </Typography>
        <Typography variant="h6" gutterBottom>
          Text to Speech (6 Music)
        </Typography>
        <Typography variant="body1" gutterBottom>
          You can use this tool to generate synthetic text using the AWS Polly
          platform. Select a voice and enter the text you
          require.
          <Container maxWidth="md">
            {/* <FormControl sx={{ width: 150, m: 1 }}>
              <InputLabel id="format-label">Format</InputLabel>
              <Select
                labelId="format-label"
                id="format"
                value={format}
                label="Format"
                onChange={(event) => {
                  setFormat(event.target.value);
                }}
              >
                <MenuItem value="pcm">wav</MenuItem>
                <MenuItem value="mp3">mp3</MenuItem>
              </Select>
            </FormControl> */}
            <FormControl sx={{ width: 150, m: 1 }}>
              <InputLabel id="voice-label">Voice</InputLabel>
              <Select
                labelId="voice-label"
                id="voice"
                value={voice}
                label="Voice"
                onChange={(event) => {
                  setVoice(event.target.value);
                }}
              >
                <MenuItem value="Brian">Male</MenuItem>
                <MenuItem value="Emma">Female</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: 400, m: 1 }}>
              <TextField
                id="outlined-multiline-static"
                label="Multiline"
                multiline
                rows={4}
                value={textinput}
                defaultValue="Text to speak"
                onChange={(event) => {
                  setTextInput(event.target.value);
                }}
              />
            </FormControl>
            <FormControl sx={{ width: 350, m: 1 }}>
              <Button
                sx={{ m: 1, float: "right" }}
                variant="contained"
                onClick={speak}
              >
                Process
              </Button>
            </FormControl>
            <Results></Results>
          </Container>
        </Typography>
      </Container>
}
    </Box>
  );
}