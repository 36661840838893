import MediaInfo from 'mediainfo.js'
let worker = null
let queue = []
let isProcessing = false

const getFirstAudioTrack = (result) => {
  return new Promise((resolve, reject) => {
    if (!result ||
        !('media' in result) ||
        !('track' in result.media) ||
        !result.media.track ||
        result.media.track.length < 1)
      return reject("No tracks found")
    for (let track of result.media.track) {
      if (track['@type'] === 'Audio') return resolve(track)
    }
    return reject("No audio tracks found")
  })
}

const readChunk = (file) => (chunkSize, offset) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      if (event.target.error) {
        reject(event.target.error)
      }
      resolve(new Uint8Array(event.target.result))
    }
    reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize))
  })

function processQueue() {
  if (!isProcessing) {
    isProcessing = true
    if (worker == null) {
      MediaInfo().then((mi) => {
        worker = mi
        isProcessing = false
        processQueue()
      })
    } else if (queue.length > 0) {
      let item = queue.shift()
      worker
        .analyzeData(() => item.file.file.size, readChunk(item.file.file))
        .then(getFirstAudioTrack)
        .catch(item.failureCallback)
        .then(item.successCallback)
        .catch(item.failureCallback)
        .finally(() => {
          isProcessing = false
          if (queue.length > 0) processQueue()
        })
    }
  }
}

export function analyse(file, callback) {
  queue.push({
    file,
    successCallback: (track) => callback(file, track),
    failureCallback: (error) => {
      console.error(error)
      callback(file, null)
    }
  })
  processQueue()
}
