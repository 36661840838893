import * as React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { ArrowForward } from '@mui/icons-material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Alert from '@mui/material/Alert'
import ButtonGroup from '@mui/material/ButtonGroup'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: 0,
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
};

function PluginItem(props) {
    return (
        <ListItem
            key={props.plugin.name}
            secondaryAction={
            <IconButton edge="end" aria-label="comments" onClick={() => props.setPluginDetails(props.plugin.name)}>
                <ArrowForward />
            </IconButton>
            }
            disablePadding
        >
            <ListItemButton
                role={undefined}
                dense
                disableRipple
                onClick={() => {
                    if (!props.selected) props.setSelected(p => p.concat([props.plugin]))
                    else props.setSelected(p => p.filter(item => item.name !== props.plugin.name))
                }}
            >
                <ListItemIcon>
                    <Checkbox
                    edge="start"
                    checked={props.selected}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': props.plugin.path }}
                    />
                </ListItemIcon>
                <ListItemText primary={props.plugin.name} />
            </ListItemButton>
        </ListItem>
    )
}

function usePluginDetail(pluginDetails, plugins) {
    if (pluginDetails !== '') {
        const plugin = plugins.find((p) => p.name === pluginDetails)
        if (plugin !== undefined) return plugin
    }
    return {
        description: ''
    }
}

export default function ExportModal(props) {
    const [format, setFormat] = React.useState('wav')
    const [normalise, setNormalise] = React.useState('')
    const [sampleRate, setSampleRate] = React.useState('')
    const [sampleFormat, setSampleFormat] = React.useState('')
    const [expertMode, setExpertMode] = React.useState(false)
    const [selectedPlugins, setSelectedPlugins] = React.useState([])
    const [pluginDetails, setPluginDetails] = React.useState('')
    const [warnings, setWarnings] = React.useState([])
  
    const pluginDetail = usePluginDetail(pluginDetails, props.plugins)

    const handleExport = () => {
        props.handleExport({
            format,
            normalise,
            sampleRate,
            sampleFormat,
            selectedPlugins
        })
        props.onClose()
    }

    React.useEffect(() => {
        let warns = new Set()
        props.selectedFiles.map(selectedID => {
            let selectedFile = props.files.find(item => item.id === selectedID)
            if (selectedFile === undefined) return console.log("File not found")
            return selectedPlugins.map(plugin => {
                return plugin.getWarnings(selectedFile)
            }).map(results => {
                return results.forEach(result => warns.add(result))
            })
        })
        warns = Array.from(warns)
        if (warns.length === 0) setWarnings([])
        else setWarnings(warns)
    
    }, [props.selectedFiles, props.files, selectedPlugins])

    return (
    <Modal open={props.open} onClose={props.onClose}>
      <Box sx={style}>
        <Box hidden={pluginDetails!==''}>
        <FormControl sx={{width: 150, m: 1}}>
            <InputLabel id="format-label">Format</InputLabel>
            <Select
            labelId="format-label"
            id="format"
            value={format}
            label="Format"
            onChange={(event) => {
                setFormat(event.target.value)
                setSampleFormat('')
            }}>
            <MenuItem value='wav'>WAV</MenuItem>
            <MenuItem value='flac'>FLAC</MenuItem>
            </Select>
        </FormControl>
        <FormControl sx={{width: 150, m: 1}}>
            <InputLabel id="normalise-label">Normalisation</InputLabel>
            <Select
            labelId="normalise-label"
            id="normalise"
            value={normalise}
            label="Normalisation"
            onChange={(event) => setNormalise(event.target.value)}
            >
            <MenuItem value=''><em>Bypass</em></MenuItem>
            <MenuItem value='ebur128'>-23 LUFS (EBU R128)</MenuItem>
            <MenuItem value='aestd1008'>-18 LUFS (AES TD1008)</MenuItem>
            <MenuItem value='peak-1'>Peak -1dBFS</MenuItem>
            <MenuItem value='peak-10'>Peak -10dBFS</MenuItem>
            </Select>
        </FormControl>
        <FormControl sx={{width: 150, m: 1}}>
            <InputLabel id="sr-label">Sample rate</InputLabel>
            <Select
            labelId="sr-label"
            id="sr"
            value={sampleRate}
            label="Sample rate"
            onChange={(event) => setSampleRate(event.target.value)}
            >
            <MenuItem value=''><em>Original</em></MenuItem>
            {expertMode && (<MenuItem value='8000'>8 kHz</MenuItem>)}
            {expertMode && (<MenuItem value='16000'>16 kHz</MenuItem>)}
            {expertMode && (<MenuItem value='32000'>32 kHz</MenuItem>)}
            <MenuItem value='44100'>44.1 kHz</MenuItem>
            <MenuItem value='48000'>48 kHz</MenuItem>
            {expertMode && (<MenuItem value='96000'>96 kHz</MenuItem>)}
            </Select>
        </FormControl>
        <FormControl sx={{width: 150, m: 1}}>
            <InputLabel id="sf-label">Sample format</InputLabel>
            <Select
            labelId="sf-label"
            id="sf"
            value={sampleFormat}
            label="Sample format"
            onChange={(event) => setSampleFormat(event.target.value)}
            >
            <MenuItem value=''><em>Original</em></MenuItem>
            {expertMode && format !== 'flac' && (<MenuItem value='u8'>8-bit PCM</MenuItem>)}
            <MenuItem value='s16le'>16-bit PCM</MenuItem>
            <MenuItem value='s24le'>24-bit PCM</MenuItem>
            {expertMode && format !== 'flac' && (<MenuItem value='s32le'>32-bit PCM</MenuItem>)}
            {expertMode && format !== 'flac' && (<MenuItem value='f32le'>32-bit float</MenuItem>)}
            {expertMode && format !== 'flac' && (<MenuItem value='f64le'>64-bit float</MenuItem>)}
            </Select>
        </FormControl>
        {props.plugins.length > 0 &&
        <FormControl fullWidth={true} sx={{p: 1}}>
        <Accordion
            defaultExpanded={selectedPlugins.length > 0}
            disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Plugins</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{p: 0}}>
            <List
                dense
                disablePadding
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}
            >
            {props.plugins.map((plugin) =>
                <PluginItem
                    plugin={plugin}
                    key={`${plugin.name}`}
                    selected={selectedPlugins.find((p) => p.name===plugin.name) !== undefined}
                    setSelected={setSelectedPlugins}
                    setPluginDetails={setPluginDetails}
                />
            )}
            </List>
          </AccordionDetails>
        </Accordion></FormControl>
        }
        {warnings.length > 0 &&
        <FormControl fullWidth={true}>
            {warnings.map(warning => (<Alert severity='error' sx={{m: 1}} key={warning}>{warning}</Alert>))}
        </FormControl>
        }
        <FormControl sx={{minWidth: 150, m: 1}}>
        <FormControlLabel
            control={<Switch
                checked={expertMode}
                onChange={() => {
                    setExpertMode((prev) => !prev)
                    setSampleRate('')
                    setSampleFormat('')
                }}/>
            }
            label="Expert mode" />
        </FormControl>
        <Button
            sx={{m: 1, float: 'right'}}
            variant="contained"
            disabled={warnings.length > 0}
            onClick={handleExport}>
            Export
        </Button>
        </Box>
        <Box hidden={pluginDetails===''}>
            <ButtonGroup>
                <IconButton aria-label="back" onClick={() => setPluginDetails('')}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant='h6' sx={{m: 1}}>{pluginDetails}</Typography>
            </ButtonGroup>
            <Typography variant='body1' gutterBottom>{pluginDetail.description}</Typography>
            {pluginDetail.uploadRequired &&
                <Alert severity="info" sx={{mt: 2}}>This plugin will upload your files to a remote server for processing.</Alert>
            }
        </Box>
      </Box>
    </Modal>
    );
  }