export function generateSpeech(format, voice, inputtext){

    fetch("https://services.audio.bbctest01.uk/polly/" + format + "/" + voice + "/" + inputtext)
        // fetch(
        //   "http://localhost:3000/polly/" +
        //     format +
        //     "/" +
        //     voice +
        //     "/" +
        //     inputtext
        // )
          .then((response) => response.json())
          .then((json) => {
            console.log(json.urlproc);
            if (json.error === 1) {
              document.getElementById("result").innerHTML = json.type;
            } else {
              document.getElementById("audioSource").src = json.url;
              document.getElementById("audioPlayback").load();
              document.getElementById("result").innerHTML =
                "Original speech ready to play.";
            //setTimeout(() => {
             document.getElementById("audioSourceProc").src = json.urlproc;
             document.getElementById("audioPlaybackProc").load();
             document.getElementById("resultProc").innerHTML =
               "Robot speech ready to play.";
            //}, 1000);
             
              
            }
          })
          .catch((error) => {
            document.getElementById("result").innerHTML = error;
          }); 
  
     
}